<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-31 10:38:19
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:47
 * @Description: 创建 修改 消息通知
 * @FilePath: \src\views\Frame\Notify\NUNotify.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="消息类型：" style="width: 90%">
            <!-- <el-radio v-model="m.type"
                      :label="120011">私信</el-radio>
            <el-radio v-model="m.type"
                      :label="120012">公共消息</el-radio> -->
            <el-radio v-model="m.type" :label="120013">域消息</el-radio>
            <el-radio v-model="m.type" :label="120014">系统消息</el-radio>
          </el-form-item>

          <el-form-item label="消息标题：" style="width: 90%" class="title-box">
            <el-input v-model="m.title" suffix-icon="xxxx"></el-input>
          </el-form-item>

          <el-form-item label="过期时间：" style="width: 45%">
            <el-date-picker
              v-model="m.expire_time"
              size="small"
              type="datetime"
              placeholder="过期时间"
              default-time="23:59:59"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label="接收域："
            v-if="m.type === 120013"
            style="width: 45%"
          >
            <el-select size="small" v-model="m.kor_id">
              <el-option
                v-for="item in korList"
                :key="item.korganization_id"
                :label="item.kor_name"
                :value="item.korganization_id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="接收用户："
            v-if="m.type === 120011 || m.type === 120012"
            style="width: 45%"
          >
            <el-select size="small" v-model="m.user_id">
              <el-option
                v-for="item in kuserList"
                :key="item.korganization_id"
                :label="item.kor_name"
                :value="item.korganization_id"
              ></el-option>
            </el-select>
          </el-form-item>

          <div ref="editor1"></div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNotify()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateNotify()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import Editor from "wangeditor";
import { createNotify, updateNotify, getNotifyInfo } from "./api"; //页面专有接口
import { getKorIdList } from "../Auth/api";
export default {
  data() {
    return {
      m: {
        type: 120011,
        title: "",
        expire_time: "",
        kor_id: "",
        user_id: "",
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      korList: [], //组织ID列表
      kuserList: [], //用户
    };
  },
  methods: {
    //打开组件
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this._GetKorIdList(); //获取组织列表
      this.$nextTick(() => {
        if (id === 0) {
          //0 新建
          this.title = "新建通知消息";
          this.loading = false;
        } else {
          //修改
          this.title = "修改通知消息";
          this._GetMenuInfo(id);
        }
        this.initEditor(); //创建编辑器
      });
    },

    //创建编辑器
    initEditor() {
      const editor = new Editor(this.$refs.editor1);

      editor.config.height = 200; //设置高度
      editor.config.zIndex = 1500; //显示高度Zindex值

      // 配置菜单栏，删减菜单，调整顺序
      editor.config.menus = ["bold", "head", "link", "italic", "underline"];

      editor.create();
    },

    //创建新菜单
    _CreateNotify() {
      var m = this.m;
      createNotify({
        parent_id: m.parent_id, //上级菜单ID
        type: m.type, //菜单类型
        icon: m.icon, //菜单图标
        name: m.name, //菜单名称
        sort: m.sort, //显示排序
        route: m.route, //路由地址
        uri: m.uri, //组件路径
        verify: m.verify, //认证状态
        show: m.show, //显示状态
        status: m.status, //菜单状态
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改菜单
    _UpdateNotify() {
      var m = this.m;
      updateNotify({
        menu_id: this.id, //菜单ID
        parent_id: m.parent_id, //上级菜单ID
        type: m.type, //菜单类型
        icon: m.icon, //菜单图标
        name: m.name, //菜单名称
        sort: m.sort, //显示排序
        route: m.route, //路由地址
        uri: m.uri, //组件路径
        status: m.status, //菜单状态
        show: m.show, //显示状态
        verify: m.verify, //认证状态
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //加载组织列表数据
    _GetKorIdList() {
      getKorIdList()
        .then((res) => {
          this.korList = res.data.data; //重写数据
          this.$nextTick(() => {
            this.m.kor_id = res.data.data[0]["korganization_id"];
          });
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取指定菜单参数
    _GetNotifyInfo(id) {
      getNotifyInfo({
        menu_id: id,
      })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.parent_id = d.kme_parent; //上级菜单ID
          m.type = d.kme_type; //菜单类型
          m.icon = d.kme_icon; //菜单图标
          m.name = d.kme_name; //菜单名称
          m.sort = d.kme_sort; //显示排序
          m.route = d.kme_route; //路由地址
          m.uri = d.kme_uri; //组件路径
          m.status = d.kme_status; //菜单状态
          m.show = d.kme_show; //显示状态
          m.verify = d.kme_verify; //认证状态
          this.loading = false;
        })
        .catch((err) => {
          this.isShow = false;
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
};
</script>

<style>
.title-box .el-form-item__content {
  width: calc(100% - 100px);
}
</style>
