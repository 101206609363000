/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-19 11:07:53 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:06:53
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\Frame\Notify\api.js
 */

import http from "@/api";

// 创建新消息通知
export const createNotify = (params) =>
  http.post("/api/v1/Notify/CreateNewNotify", params);

// 修改新消息通知
export const updateNotify = (params) =>
  http.put("/api/v1/Notify/UpdateNotify", params);

// 删除新消息通知
export const delNotify = (params) =>
  http.delete("/api/v1/Notify/DelNotify", params);

// 获取消息通知内容表格
export const getNotifyContentTable = (params) =>
  http.get("/v1/Notify/GetNotifyContentTable", params);

// 根据ID查询指定新消息通知信息
export const getNotifyInfo = (params) =>
  http.get("/api/v1/Notify/GetNotifyInfo", params);
